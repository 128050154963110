/* Local CSS */
@import './colors.css';
@import './neutral-colors.css';
@import './success-colors.css';
@import './primary-colors.css';
@import './warning-colors.css';
@import './fonts.css';
@import './button.css';

.App {
    text-align: center;
    height: 100vh !important;
}

.remotive-dropdown-light {
    background-color: #ffffff !important;
    opacity: 0.91 !important;
}

.remotive-dropdown-light > a {
    color: #000 !important;
    opacity: 1 !important;
}

.remotive-dropdown-light > a:hover {
    color: rgb(158, 158, 158) !important;
    opacity: 1 !important;
}

.remotive-dropdown-dark {
    background-color: #000000 !important;
    opacity: 0.91 !important;
}

.remotive-dropdown-dark > a {
    color: #fff !important;
    opacity: 1 !important;
}

.remotive-dropdown-dark > button:hover {
    color: #000 !important;
    opacity: 1 !important;
}

.remotive-dropdown-dark > a:hover {
    color: #000 !important;
    background-color: #a9a9a9 !important;
    opacity: 1 !important;
}


/* ### Css for react-dropzone ### */
.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #7ca1c5 !important;
    border-style: dashed !important;
    background-color: #edf4fa;
    color: black;
    outline: none;
    transition: border 0.24s ease-in-out;
    cursor: pointer !important;
    pointer-events: all !important;
}

.dropzone:focus {
    border-color: #2196f3;
}

/* ### Customized Bootstrap components ### */
.form-check-input:checked {
    background-color: #0b3f70 !important;
    border-color: #0b3f70 !important;
}

/* Hide the small dropd own arrow on drop downs because we want to replace it with the material design drop down arrow icon */
.dropdown ::after {
    display: none !important;
}

/* Hide the small drop down arrow on drop ups because it interferes with the mobile navbar */
.dropup ::after {
    display: none !important;
}

.border-light-gray {
    border-color: #d2d2d2 !important;
}

.progress-bar {
    background-color: #0b3f70 !important;
  }

/* Hide the blue outline shadow and underline when an accordion is expanded */
.accordion-button:focus {
    box-shadow: none !important;
    border-color: rgba(0,0,0,.125) !important;
}

/* Hide the blue background of the accordion exapnd/collapse button that spans across the entire accordion */
.accordion-item{
    background-color: transparent !important;
}

/* Remove the font color change that is applied to the header when an accordion is expanded */
.accordion-button:not(.collapsed){
    color: inherit !important;
}

.accordion-button{
    background-color: transparent !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 8px !important;
    padding-top: 8px !important;
}

/* ### Flexbox short hand ### */
.flex-truncate{
    min-width: 0;
}

/* ### Styling for hubspot chat */
#hubspot-messages-iframe-container.widget-align-right{
    margin-bottom: 50px;
}

.initial-message-bubble{
    background-color: #FFFD !important;
}

/* ### Styling for feedback plugin ### */
#feedback-submit{
    border-radius: 50px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;

    background-color: #16a468 !important;
    color: #fff !important;
    border-color: #16a468 !important;
    border-width: 2px !important;   
}

#feedback-loading{
    border-radius: 50px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;

    background-color: #16a468 !important;
    color: #fff !important;
    border-color: #16a468 !important;
    border-width: 2px !important;   
}

#feedback-back{
    border-radius: 50px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;

    background-color: #05315a !important;
    color: #fff !important;
    border-color: #05315a !important;
    border-width: 2px !important;
}

.feedback-btn-wrapper {
    opacity: 0.95 !important;
    margin-bottom: 1rem !important;
    margin-right: 1rem !important;
}

.popover-arrow {
    border-width: 0px !important;
}

.feedback-wrapper {
    opacity: 0.95 !important;
    margin-bottom: 1rem !important;
    margin-right: 1rem !important;
}

.feedback-main {
    font-family: 'LexendDecaRegular' !important;
}

#feedback-btn {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

#feedback-email {
    font-family: 'LexendDecaRegular' !important;
    font-size: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
}

#feedback-message {
    font-family: 'LexendDecaRegular' !important;
    font-size: 14px;
}

#feedback-back {
    font-family: 'LexendDecaRegular' !important;
}

#feedback-submit {
    font-family: 'LexendDecaRegular' !important;
}

#feedback-btn span {
    font-size: 12px !important;
    font-family: 'LexendDecaRegular' !important;
}

.opacity-65{
    opacity: 0.65 !important;
}

.recording-session-tab-parent-active li {
    z-index: 9999;
}

/* ********************** Custom bootstrap ********************** */

.dropdown-menu{
    --bs-dropdown-min-width: 2rem !important;
}

.no-bg-modal{
    background-color: transparent !important;
    border: 0px !important;
 }

pre {
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-left: 3px solid #f36d33;
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
}

 .accordion-button:not(.collapsed) {
    box-shadow: unset !important;
 }
