/* Success */
.remotive-btn-success {
    background-color: #16a468 !important;
    color: #fff !important;
    border-color: #16a468 !important;
    border-width: 2px !important;
}

.remotive-btn-success:hover {
    background-color: #044132 !important;
    color: #fff !important;
    border-color: #044132 !important;
    border-width: 2px !important;
}

.remotive-btn-success:active {
    background-color: #fff !important;
    color: #16a468 !important;
    border-color: #fff !important;
    border-width: 2px !important;
}

.remotive-btn-success:focus {
    outline-style: solid !important;
    outline-color: #05315a50 !important;
    outline-width: 3px !important;
    background-color: #16a468 !important;
    color: #fff !important;
    border-color: #16a468 !important;
    border-width: 2px !important;
}

.remotive-btn-success:disabled {
    background-color: #b4b3b3 !important;
    color: #fff !important;
    border-color: #b4b3b3 !important;
    border-width: 2px !important;
    cursor: not-allowed !important;
    pointer-events: all !important;
}

/* Primary */
.remotive-btn-primary {
    background-color: #05315a !important;
    color: #fff !important;
    border-color: #05315a !important;
    border-width: 2px !important;
}

.remotive-btn-primary:hover {
    background-color: #4375a5 !important;
    color: #fff !important;
    border-color: #4375a5 !important;
    border-width: 2px !important;
}

.remotive-btn-primary:active {
    background-color: #fff !important;
    color: #05315a !important;
    border-color: #fff !important;
    border-width: 2px !important;
}

.remotive-btn-primary:focus {
    outline-style: solid !important;
    outline-color: #05315a50 !important;
    outline-width: 3px !important;
    background-color: #05315a !important;
    color: #fff !important;
    border-color: #05315a !important;
    border-width: 2px !important;
}

.remotive-btn-primary:disabled {
    background-color: #b4b3b3 !important;
    color: #fff !important;
    border-color: #b4b3b3 !important;
    border-width: 2px !important;
    cursor: not-allowed !important;
    pointer-events: all !important;
}

/* Primary light */
.remotive-btn-primary-dark {
    background-color: #00182e !important;
    color: #fff !important;
    border-color: #00182e !important;
    border-width: 2px !important;
}

.remotive-btn-primary-dark:hover {
    background-color: #0b3f70 !important;
    color: #fff !important;
    border-color: #0b3f70 !important;
    border-width: 2px !important;
}

.remotive-btn-primary-dark:active {
    background-color: #fff !important;
    color: #0b3f70 !important;
    border-color: #fff !important;
    border-width: 2px !important;
}

.remotive-btn-primary-dark:focus {
    outline-style: solid !important;
    outline-color: #4375a550 !important;
    outline-width: 3px !important;
    background-color: #0b3f70 !important;
    color: #fff !important;
    border-color: #0b3f70 !important;
    border-width: 2px !important;
}

.remotive-btn-primary-dark:disabled {
    background-color: #838d95 !important;
    color: #fff !important;
    border-color: #838d95 !important;
    border-width: 2px !important;
    cursor: not-allowed !important;
    pointer-events: all !important;
}



/* Primary without background */
.remotive-btn-no-bg {
    background-color: transparent !important;
    color: #0b3f70 !important;
    border-width: 0px !important;
}

.remotive-btn-no-bg:hover {
    background-color: transparent !important;
    color: #7ca1c5 !important;
    border-width: 0px !important;
}

.remotive-btn-no-bg:active {
    background-color: transparent !important;
    color: #05315a !important;
    border-width: 0px !important;
}

.remotive-btn-no-bg:focus {
    outline-style: solid !important;
    outline-color: #05315a50 !important;
    outline-width: 0px !important;
    background-color: transparent !important;
    color: #7ca1c5 !important;
    border-width: 0px !important;
}

.remotive-btn-no-bg:disabled {
    background-color: transparent !important;
    color: #aaafb6 !important;
    border-color: #aaafb6 !important;
    border-width: 0px !important;
    cursor: not-allowed !important;
    pointer-events: all !important;
}

/* Primary without background, suitable for tabs */
.remotive-btn-tab {
    background-color: transparent !important;
    color: #7ca1c5 !important;
    border-width: 0px !important;
}

.remotive-btn-tab:hover {
    background-color: transparent !important;
    color: #00182e !important;
    border-width: 0px !important;
}

.remotive-btn-tab:active {
    background-color: transparent !important;
    color: #05315a !important;
    border-width: 0px !important;
}

.remotive-btn-tab:focus {
    outline-style: solid !important;
    outline-color: #05315a50 !important;
    outline-width: 0px !important;
    background-color: transparent !important;
    color: #7ca1c5 !important;
    border-width: 0px !important;
}

.remotive-btn-tab:disabled {
    background-color: transparent !important;
    color: #aaafb6 !important;
    border-color: #aaafb6 !important;
    border-width: 0px !important;
    cursor: not-allowed !important;
    pointer-events: all !important;
}

/* Primary without background, suitable for navbar */
.remotive-btn-nav {
    background-color: transparent !important;
    color: #59d48a !important;
    border-width: 0px !important;
}

.remotive-btn-nav:hover {
    background-color: transparent !important;
    color: #fff !important;
    font-weight: 900 !important;
    border-width: 0px !important;
}

.remotive-btn-nav:active {
    background-color: transparent !important;
    color: #fff !important;
    border-width: 0px !important;
}

.remotive-btn-nav:focus {
    outline-style: solid !important;
    outline-color: #05315a50 !important;
    outline-width: 1px !important;
    background-color: transparent !important;
    color: #e5fae7 !important;
    border-width: 0px !important;
}

.remotive-btn-nav:disabled {
    background-color: transparent !important;
    color: #747474 !important;
    border-color: #aaafb6 !important;
    border-width: 0px !important;
    font-weight: inherit !important;
    cursor: not-allowed !important;
    pointer-events: all !important;
}


/* Secondary */
.remotive-btn-secondary {
    background-color: #fff !important;
    color: #05315a !important;
    border-color: #05315a !important;
    border-width: 2px !important;
}

.remotive-btn-secondary:hover {
    background-color: #4375a5 !important;
    color: #fff !important;
    border-color: #4375a5 !important;
    border-width: 2px !important;
}

.remotive-btn-secondary:active {
    background-color: #fff !important;
    color: #05315a !important;
    border-color: #fff !important;
    border-width: 2px !important;
}

.remotive-btn-secondary:focus {
    outline-style: solid !important;
    outline-color: #05315a50 !important;
    outline-width: 3px !important;
    background-color: #fff !important;
    color: #05315a !important;
    border-color: #05315a !important;
    border-width: 2px !important;
}

.remotive-btn-secondary:disabled {
    background-color: #b4b3b3 !important;
    color: #fff !important;
    border-color: #b4b3b3 !important;
    border-width: 2px !important;
    cursor: not-allowed !important;
    pointer-events: all !important;
}

/* Transparent (only a white border) */
.remotive-btn-transparent {
    background-color: transparent !important;
    color: #fff;
    border-color: #fff !important;
    border-width: 1px !important;
}

.remotive-btn-transparent:hover {
    background-color: #fff !important;
    color: #000 !important;
    border-color: #000 !important;
    border-width: 1x !important;
}

.remotive-btn-transparent:active {
    background-color: transparent !important;
    color: #fff !important;
    border-color: transparent;
    border-width: 1px !important;
}

.remotive-btn-transparent:focus {
    outline-style: solid !important;
    outline-color: #d8d8d8d3 !important;
    outline-width: 3px !important;
    background-color: transparent !important;
    color: #fff !important;
    border-color: #fff !important;
    border-width: 1px !important;
}

.remotive-btn-transparent:disabled {
    background-color: #b4b3b3 !important;
    color: #fff !important;
    border-color: #b4b3b3 !important;
    border-width: 1px !important;
    cursor: not-allowed !important;
    pointer-events: all !important;
}

/* Button sizes */

.remotive-btn {
    border-radius: 50px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.remotive-btn-md {
    border-radius: 50px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    font-size: 12px !important;
}

.remotive-btn-sm {
    border-radius: 50px !important;
    padding-left: 11px !important;
    padding-right: 11px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    margin: 5px !important;
    font-size: 12px !important;
}
