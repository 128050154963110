.remotive-gradient {
    background: radial-gradient(183.91% 56.41% at 13.33% 6.53%, #05345a 13.13%, #001324 93.52%) !important;
}

.remotive-gradient-horizontal {
    background: radial-gradient(100.41% 183.91% at 13.33% 6.53%, #0f68b1 13.13%, #001324 103.52%) !important;
}

.remotive-gradient-sm {
    background: radial-gradient(183.91% 96.41% at 53.33% 15.53%, #05345a 13.13%, #001324 93.52%) !important;
}

.remotive-main-background {
    background-color: #eaf5ff;
}