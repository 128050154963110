/* Primary 0 */
.remotive-primary-0-color {
    color: #fcfcff !important;
}

.remotive-primary-0-background {
    background-color: #fcfcff !important;
}

.remotive-primary-0-border {
    border-color: #fcfcff !important;
}

/* Primary 5 */
.remotive-primary-5-color {
    color: #f1f6fa !important;
}

.remotive-primary-5-background {
    background-color: #f1f6fa !important;
}

.remotive-primary-5-border {
    border-color: #f1f6fa !important;
}

/* Primary 10 */
.remotive-primary-10-color {
    color: #edf4fa !important;
}

.remotive-primary-10-background {
    background-color: #edf4fa !important;
}

.remotive-primary-10-border {
    border-color: #edf4fa !important;
}

/* Primary 20 */
.remotive-primary-20-color {
    color: #d2e1ee !important;
}

.remotive-primary-20-background {
    background-color: #d2e1ee !important;
}

.remotive-primary-20-border {
    border-color: #d2e1ee !important;
}

/* Primary 30 */
.remotive-primary-30-color {
    color: #b7cde2 !important;
}

.remotive-primary-30-background {
    background-color: #b7cde2 !important;
}

.remotive-primary-30-border {
    border-color: #b7cde2 !important;
}

/* Primary 40 */
.remotive-primary-40-color {
    color: #7ca1c5 !important;
}

.remotive-primary-40-background {
    background-color: #7ca1c5 !important;
}

.remotive-primary-40-border {
    border-color: #7ca1c5 !important;
}

/* Primary 50 */
.remotive-primary-50-color {
    color: #4375a5 !important;
}

.remotive-primary-50-background {
    background-color: #4375a5 !important;
}

.remotive-primary-50-border {
    border-color: #4375a5 !important;
}

/* Primary 60 */
.remotive-primary-60-color {
    color: #0b3f70 !important;
}

.remotive-primary-60-background {
    background-color: #0b3f70 !important;
}

.remotive-primary-60-border {
    border-color: #0b3f70 !important;
}

/* Primary 70 */
.remotive-primary-70-color {
    color: #05315a !important;
}

.remotive-primary-70-background {
    background-color: #05315a !important;
}

.remotive-primary-70-background-transparent {
    background-color: #05315aE0 !important;
}

.remotive-primary-70-border {
    border-color: #05315a !important;
    border-width: 2px !important;
}

/* Primary 80 */
.remotive-primary-80-color {
    color: #002649 !important;
}

.remotive-primary-80-background {
    background-color: #002649 !important;
}

.remotive-primary-80-border {
    border-color: #002649 !important;
}

/* Primary 90 */
.remotive-primary-90-color {
    color: #001c36 !important;
}

.remotive-primary-90-background {
    background-color: #001c36 !important;
}

.remotive-primary-90-border {
    border-color: #001c36 !important;
}

/* Primary 100 */
.remotive-primary-100-color {
    color: #00182e !important;
}

.remotive-primary-100-background {
    background-color: #00182e !important;
}

.remotive-primary-100-border {
    border-color: #00182e !important;
}

/* Background */
.remotive-gradient-background {
    background: radial-gradient(183.91% 56.41% at 13.33% 6.53%, #05345a 13.13%, #001324 93.52%) !important;
}
